const getBaseUrl = () => {
  switch (process.env.REACT_APP_ENV || "development") {
    case "development":
      return "http://localhost:3000/v1";
    case "stg-dev":
      return "https://dev-efb-sop-api.thewebops.com/v1";
    case "staging":
      return "https://efb-sop-api.thewebops.com/v1";
    case "production":
      return "https://sops-05794990-api.efb.eg/v1";
    default:
      return "https://dev-efb-sop-api.thewebops.com/v1";
  }
};

export const API_BASE_URL = getBaseUrl();
