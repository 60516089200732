export const customTheme = {
  colors: {
    background: "#16211F",
    main: "#FFFFFF !important",
    secondary: "#131313",
    tableBorder: "#c7c7c7",
  },
  header: {
    logoWidth: "100px",
    background: "#16211F"
  },
  sidebar: {
    customTheme: {
      background: "#16211F",
      textColor: "#FFFFFF",
    },
  },
  authLogo: {
    width: "140px",
  },
};
