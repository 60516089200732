import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "menuItem",
  pluralName: "menuItems",
  pathName: "menuItems",
  endpoint: "/admin/menu_items",
  serverName: "menu_item",
  serverPluralName: "menu_items",
  displayName: { en: "Menu Items", ar: "القوائم" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  requestParams: {
    list: {
      includes: 'parent',
    },
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      type: "string",
      inList: true,
      required: true,
      isSearchable: true,
      localized: true,
      locales: [
        {
          locale: 'en',
          labelSuffix: {
            en: 'english',
            ar: 'الإنجليزية',
          },
          required: true,
          usedToShow: true,
          inList: true,
        },
        {
          locale: 'ar',
          labelSuffix: {
            en: 'arabic',
            ar: 'عربى',
          },
          required: true,
          usedToShow: true,
          inList: true,
        },
      ]
    },
    {
      identifier: "parent_id",
      label: { en: "Parent Menu Item", ar: "القائمه الاعلي" },
      formAccessor: (dataObject) => (dataObject.parent ? dataObject.parent.id : null),
      accessor: (dataObject) => (dataObject.parent ? dataObject.parent.name : "-"),
      type: "reference",
      inList: true,
      isSearchable: true,
      optionsEntity: {
        name: "menuItem",
        labelField: (dataObject) => `${dataObject.name}`,
        valueField: "id",
      },
    },
    {
      identifier: "link",
      label: { en: "Link", ar: "الرابط" },
      accessor: "link",
      type: "string",
      required: true
    },
    {
      identifier: "link_type",
      label: { en: "Internal Link", ar: "رابط داخلي" },
      accessor: "link_type",
      type: "toggle",
      defaultValue: "external",
      toggleConfig: {
        on: "internal",
        off: "external",
      },
      inList: true,
    }, 
    {
      identifier: "index",
      label: { en: "Index", ar: "الترتيب" },
      accessor: "index",
      type: "number",
      required: true,
      defaultValue: 1,
      min: 1,
    }

  ],
  filters: [
    {
      label: { en: "Parent Menu Item", ar: "القائمه الرئيسية" },
      name: "parent_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        isSearchable: true,
        optionsEntity: {
          name: "menuItem",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ]
});
