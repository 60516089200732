import admin from "./_Admin";
import category from "./Category";
import page from "./Page";
import menuItem from "./MenuItem";

const userDefinedEntities = {
  admin,
  category,
  page,
  menuItem
};

const groups = [
  {
    name: "ungrouped",
    entities: [
      admin,
      category,
      page,
      menuItem
    ],
  },
];
export { userDefinedEntities, groups };
