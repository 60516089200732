import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "category",
  pluralName: "categories",
  pathName: "categories",
  endpoint: "/admin/categories",
  serverName: "category",
  serverPluralName: "categories",
  displayName: { en: "Categories", ar: "التصنيفات" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  authorizedRoles: {},
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "title",
      label: { en: "Title", ar: "الاسم" },
      accessor: "title",
      type: "string",
      inList: true,
      required: true,
      isSearchable: true,
      localized: true,
      locales: [
        {
          locale: 'en',
          labelSuffix: {
            en: 'english',
            ar: 'الإنجليزية',
          },
          required: true,
          usedToShow: true,
          inList: true,
        },
        {
          locale: 'ar',
          labelSuffix: {
            en: 'arabic',
            ar: 'عربى',
          },
          required: true,
          usedToShow: true,
          inList: true,
        },
      ]
    },
    {
      identifier: "parent_id",
      label: { en: "Parent Category", ar: "الفئة الرئيسية" },
      formAccessor: (dataObject) => (dataObject.parent ? dataObject.parent.id : null),
      accessor: (dataObject) => (dataObject.parent ? dataObject.parent.title : "-"),
      type: "reference",
      inList: true,
      isSearchable: true,
      optionsEntity: {
        name: "category",
        labelField: (dataObject) => `${dataObject.title}`,
        valueField: "id",
      },
    },
  ],
  filters: [
    {
      label: { en: "Parent Category", ar: "الفئة الرئيسية" },
      name: "parent_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        isSearchable: true,
        optionsEntity: {
          name: "category",
          labelField: "title",
          valueField: "id",
        },
      },
    },
  ]
});
