import _login from "./_login";
import _forgetPassword from "./_forgetPassword";
import _resetPassword from "./_resetPassword";
import _completeProfile from "./_completeProfile";
import _register from "./_register";
import _resendConfirmationEmail from "./_resendConfirmationEmail";
import _confirmProfile from "./_confirmProfile";

const authConfig = {
  name: "admin",
  pluralName: "admins",
  pathName: "admins",
  serverName: "admin",
  serverPluralName: "admins",
  displayName: "Admin",
  endpoint: "admin/admins",
  customShowEndpoint: "self",
  saveAuthHeadersOnce: false,
  enableAccountCreation: false,
};

const authUserDefinedEntities = [
  _login,
  _forgetPassword,
  _resetPassword,
  _completeProfile,
  _register,
  _confirmProfile,
  _resendConfirmationEmail
]

export { authConfig, authUserDefinedEntities };
