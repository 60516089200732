import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import { currentAdmin } from "utils/authHelpers";
import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "admin",
  pluralName: "admins",
  pathName: "admins",
  endpoint: "/admin/admins",
  serverName: "admin",
  serverPluralName: "admins",
  displayName: { en: "Admins", ar: "المديرين" },
  allowCreate: true,
  allowEdit: (dataObject) => dataObject.id == currentAdmin()?.id,
  showRowActions: true,
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  entityIdentifier: "id",
  //authorizedRoles: {},
  rowsPerPage: 100,
  fields: [
    {
      identifier: "id",
      label: {en: "ID", ar: "الرقم التسلسلي"},
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      label: { en: "Name", ar: "الاسم" },
      identifier: "name",
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "email",
      label: { en: "Email", ar: "البريد الالكتروني" },
      accessor: "email",
      inList: true,
      type: "email",
      hideInEdit: true,
      required: true,
    },
  ],
});
